<template>
    <div class="favourites noselect">
        <div v-if="favourites.length > 0" class="tabs" >
            <div class="tabs__content">
                <v-btn @click='filterFavourites("all")' text v-ripple="false" :class="filterType=='all' ? 'is-active' : ''">Alle</v-btn>
                <v-btn @click='filterFavourites("exercise.Playlist")' text v-ripple="false" :class="filterType=='exercise.Playlist' ? 'is-active' : ''">Playlists</v-btn>
                <v-btn @click='filterFavourites("workout.WorkoutPage")' text v-ripple="false" :class="filterType=='workout.WorkoutPage' ? 'is-active' : ''">Workouts</v-btn>
                <v-btn @click='filterFavourites("blog.BlogPage")' text v-ripple="false" :class="filterType=='blog.BlogPage' ? 'is-active' : ''">Magazin</v-btn>
            </div>
        </div>

        <div v-if="filteredFavourites.length == 0" class="empty-content">
            <div class="empty-content__title">Keine Favoriten vorhanden</div>
            <p>Hier landet alles, was du innerhalb von SportlerPlus per Klick auf das ★-Symbol als Favorit markiert hast.</p>
        </div>

        <div v-for="(item, i) in filteredFavourites" :key="i" class="contentItem">
            
            <!-- Icon -->
            <div class="contentItem__icon">
                <img v-if="item.type == 'exercise.Playlist' || item.type == 'useritem.Playlist'" src="@/assets/contentitem/ic_contentitem_playlist.svg" alt="" />
                <img v-if="item.type == 'workout.WorkoutPage'" src="@/assets/contentitem/ic_contentitem_workout.svg" alt="" />
                <img v-if="item.type == 'blog.BlogPage'" src="@/assets/contentitem/ic_contentitem_magazin.svg" alt="" />
            </div>

            <!-- Title & Meta -->
            <div class="contentItem__content" @click="openFavourite(item)">
                <div class="contentItem__title">
                    {{ item.title }}
                </div>
                <div class="contentItem__meta">
                    {{ item.subtitle }}
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import {analytics} from '@/firebase/index.js';

export default {
    name: 'Favourites',
    components: { },
    data() {
        return {
            favourites: [],
            userPlaylists: [],
            filterType: "all",
        }
    },
    mounted() {
        this.init();
    },
    computed: {
        filteredFavourites() {
            let type = this.filterType;
            let result = [];
            this.favourites.forEach(function(el) {
                if (el.type == type || type == "all") {
                    result.push(el);
                }
            });
            // dynamically create entries for user playlists
            if (type == "exercise.Playlist" || type == "all") {
                this.userPlaylists.forEach((el) => {
                    if (!el.isFavourite) { return; }
                    let fav = {
                        type: "useritem.Playlist",
                        title: el.name,
                        subtitle: "Playlist - " + el.items.length + " Übungen",
                        userPlaylistId: el.uid,
                        uid: el.uid,
                        userId: el.userId,
                    }
                    result.push(fav);
                });
            }
            return result;
        }
    },
    methods: {
        openFavourite(favItem) {
            if (favItem.type == "blog.BlogPage") {
                this.$router.push("/articles/" + favItem.articleId);
            }
            else if (favItem.type == "workout.WorkoutPage") {
                this.$router.push("/workout/" + favItem.articleId);
            }
            else if (favItem.type == "exercise.Playlist") {
                this.$router.push("/playlist/start?playlist_id=" + favItem.articleId);
            }
            else if (favItem.type == "useritem.Playlist") {
                this.$router.push("/playlist/start?user_playlist_id=" + favItem.userPlaylistId);
            }
        },
        filterFavourites(type) {
            this.filterType = type;
        },
        init() {
            this.$store.dispatch('fetchAllFavourites').then((res) => this.favourites = res);
            this.$store.dispatch('fetchAllUserPlaylists').then((res) => this.userPlaylists = res);
            analytics.logEvent('list_favourites');
        },
    },

}
</script>

<style lang="scss">
    .favourites {
        display: flex;
        flex-direction: column;
        min-height: 100%;
        
        .tabs {
            margin-top: -24px;
        }

        &__empty {
            text-align: center;
            margin: auto;

            img {
                margin-bottom: 16px;
            }

            &__title {
                font-size: 20px;
                font-weight: 700;
                letter-spacing: -0.29px;
                color: rgba($colorBlack, .24);
                margin-bottom: 7px;
            }

            p {
                line-height: 20px;
                font-weight: 600;
                opacity: .24;
            }
        }
    }
</style>
